import { getI18n } from 'react-i18next';
import { action, observable, computed } from 'mobx';
import configApi, { ConfigApi } from './configApi';
import config, { getEnv } from '../config';
import { getProp } from '../utils/utils';

export default class ConfigStore {
    private configApi: ConfigApi;
    @observable attributes?: Record<string, Record<string, ILocalizedAttribute>> = undefined;
    @observable capacities?: Record<string, Record<string, ILocalizedCapacity>> = undefined;
    private attributesLoading: boolean = false;
    private capacitiesLoading: boolean = false;

    constructor() {
        this.configApi = configApi;
        // preload attributes and capacities
        setTimeout(() => {
            this.fetchAttributes();
            this.fetchCapacities();
        }, 1000);
    }

    @computed
    get currentAttributes(): Record<string, ILocalizedAttribute> | undefined {
        if (this.attributes) {
            const i18n = getI18n();
            return this.attributes[i18n.language];
        }

        return undefined;
    }

    @computed
    get currentCapacities() {
        if (this.capacities) {
            const i18n = getI18n();
            return this.capacities[i18n.language];
        }

        return undefined;
    }

    // set attributes into usable structure and add to translations
    setAttributesLang(attributes: ILocalizedAttribute[]) {
        const languageConfs: Record<string, Record<number, ILocalizedAttribute>> = {};
        const languages = config.ui.languages.map((l) => l.id);
        // gather attribute settings by language and by id
        languages.forEach((lang) => {
            const resources: Record<number, ILocalizedAttribute> = attributes.reduce(
                (acc, curr) => {
                    if (curr.id) {
                        acc[curr.id] = curr;
                    }
                    return acc;
                },
                {} as Record<number, ILocalizedAttribute>
            );

            languageConfs[lang] = resources;
            this.addAttributeResources(lang, resources);
        });

        this.attributes = languageConfs;
    }

    // add attribute localizations into lang specific translations
    addAttributeResources(lang: string, attributes: Record<number, ILocalizedAttribute>) {
        const i18n = getI18n();
        const keys: string[] = Object.keys(attributes);
        const resources: Record<number, string> = keys.reduce((acc, curr: string) => {
            const id = parseInt(curr, 10);
            const translation = getProp(attributes, [id, 'i18n', lang], null);
            if (translation) {
                acc['attribute.' + id] = translation;
            }
            return acc;
        }, {} as Record<number, string>);

        i18n.addResources(lang, 'translation', resources);
    }

    // set capacities into usable structure and add to translations
    setCapacitiesLang(capacities: ILocalizedCapacity[]) {
        const languageConfs = {};
        const languages = config.ui.languages.map((l) => l.id);
        // gather capacity settings by language and by id
        languages.forEach((lang) => {
            const resources: Record<string, ILocalizedCapacity> = capacities.reduce((acc, curr) => {
                if (curr.id) {
                    acc[curr.id] = curr;
                }
                return acc;
            }, {} as Record<string, ILocalizedCapacity>);

            languageConfs[lang] = resources;
            this.addCapacityResources(lang, resources);
        });

        this.capacities = languageConfs;
    }

    // add capacity localizations into lang specific translations
    addCapacityResources(lang: string, capacities: Record<string, ILocalizedAttribute>) {
        const i18n = getI18n();
        const keys: string[] = Object.keys(capacities);

        const resources: Record<number, string> = keys.reduce((acc, curr: string) => {
            const id = parseInt(curr, 10);
            const translation = getProp(capacities, [id, 'i18n', lang], null);
            if (translation) {
                acc['capacity.' + id] = translation;
            }
            return acc;
        }, {} as Record<number, string>);

        i18n.addResources(lang, 'translation', resources);
    }

    /**
     * Replace customer specific localization resources for different languages.
     *
     * 1. Uses environment config setting "customerLoc".
     *    By default it is env value e.g. tuomi-test, but it can be overridden in config.ts envConfig.
     * 2. Add overridden localizations under translation file json "mods.[customer].[localizationpath]"
     *    localizationpath should be full path matching existing localization resource key.
     *    E.g.
     *     "mods": {
     *         "tuomi": {
     *             "label.excessPrice": "Asiakasmaksu"
     *         }
     *     }
     */
    replaceCustomerResources() {
        const i18n = getI18n();
        const languages = config.ui.languages.map((l) => l.id);
        const customerLoc = config.customerLocale;

        // go through each defined language and find possible replacement localizations
        languages.forEach((lang) => {
            const resourceBundle = i18n.getResourceBundle(lang, 'translation');
            if (resourceBundle) {
                const customerRes = (resourceBundle.customerLocale || {})[customerLoc];
                if (customerRes && Object.keys(customerRes).length) {
                    i18n.addResources(lang, 'translation', customerRes);
                }
            }
        });
    }

    // verify that data is loaded and start loading if necessary
    verifyDataLoaded() {
        if (!this.attributes) {
            this.fetchAttributes();
        }
        if (!this.capacities) {
            this.fetchCapacities();
        }
    }

    @action async fetchAttributes() {
        if (this.attributesLoading) {
            return; // no need to fetch again right now
        }
        try {
            this.attributesLoading = true;
            const attributes = await this.configApi.getAttributes();
            this.setAttributesLang(attributes);
        } catch (e) {
            console.log('fetch attributes failed', e);
        } finally {
            this.attributesLoading = false;
        }
    }

    @action async fetchCapacities() {
        if (this.capacitiesLoading) {
            return; // no need to fetch again right now
        }

        try {
            this.capacitiesLoading = true;
            const capacities = await this.configApi.getCapacities();
            this.setCapacitiesLang(capacities);
        } catch (e) {
            console.log('fetch capacities failed', e);
        } finally {
            this.capacitiesLoading = false;
        }
    }
}
