import './AttributesList.css';
import { getProp } from '../../utils/utils';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

type ThisComponentProps = {
    attributes: AttributeDef[];
    attributeConfs?: Record<string, ILocalizedAttribute>;
};

function AttributeDisplayValue(props: {id: number, confs?: Record<string, ILocalizedAttribute>, t: any}) {
    const {id, confs, t} = props;
    // decide whether to take icon from display confs or just to show text
    const confDisplayIcon = getProp(confs, [id, 'display', 'icon'], null);
    if (confDisplayIcon) {
        return <span key={`key-attribute-${id}`} title={t(`attribute.${id}`, id + '')}>
            <FontAwesomeIcon icon={confDisplayIcon} />
        </span>;
    }
    return <span key={`key-attribute-${id}`}>{t(`attribute.${id}`, id + '')}</span>
}

const AttributesList = ({ attributes, attributeConfs }: ThisComponentProps) => {
    const { t } = useTranslation();

    return (
        <div className="attributes-list">
            {attributes.map((i) => (
                <AttributeDisplayValue key={i.id} id={i.id} confs={attributeConfs} t={t} />
            ))}
        </div>
    );
};

export default AttributesList;
