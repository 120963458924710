import axios, { AxiosInstance } from 'axios';
import config from '../config';
import { getAuth0Client } from '../react-auth0-spa';

export class ConfigApi {
    private http: AxiosInstance;

    constructor(url: string) {
        this.http = axios.create({
            baseURL: url,
        });

        // intercept all outgoing requests
        this.http.interceptors.request.use(ConfigApi.beforeRequest);

        // intercept all incoming responses
        this.http.interceptors.response.use(
            ConfigApi.onRequestSuccess,
            ConfigApi.onRequestFailure
        );
    }

    private static async beforeRequest(request) {
        const auth0 = await getAuth0Client();
        const token = await auth0.getTokenSilently();

        // add an auth header
        request.headers.Authorization = `Bearer ${token}`;

        return request;
    }

    private static onRequestSuccess(response) {
        // unwrap returned data
        return response.data.hasOwnProperty('data') ? response.data.data : response;
    }

    private static async onRequestFailure(err) {
        // check for the `401` status and force-logout, otherwise proceed with regular workflow
        if (err.response.status === 401) {
            const auth0 = await getAuth0Client();
            await auth0.logout();
        } else {
            console.log('configApi fetch failed', err);
        }

        throw err;
    }

    /**
     * Return attributes from garage-api. Throws exceptions.
     */
    async getAttributes(): Promise<ILocalizedAttribute[]> {
        return await this.http.get('/attributes');
    }

    /**
     * Return attributes from garage-api. Throws exceptions.
     */
    async getCapacities(): Promise<ILocalizedAttribute[]> {
        return await this.http.get('/capacities');
    }
}

const configApi = new ConfigApi(config.api.url);

export default configApi;